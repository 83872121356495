<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: infoCharts.vue
Description:This file gives the information about the charts (feed ,do vs hourly feed,abwvs biomass ) graphs
-->
<template>
  <el-row class="info-charts" :key="getPreferredUnits.date_format">
    <card-chart
      :key="getPreferredUnits.date_format"
      v-loading="loading.FEED || getAllGraphLoading"
      element-loading-background="white"
      :columns="getScheduleFeedColumns"
      :tableData="getFeedTableData"
      :chartConfig="getScheduleFeedChartOptions"
      :xAxisTitle="
        getSelectedPonds.length > 1 ? $t('Comn_doc_days') : $t('Comn_date')
      "
      :yAxisTitle="$t('Comn_feed_kg')"
      :timeZoneString="getUserTimeZoneString"
      :xAxisType="getScheduleFeedChartOptions.xAxis.type"
      :initDateRangeArr="getDateRangeForFeedGraph"
      :datePickerOptions="{
        disableDateMethod,
        arrShortcuts: getDayWiseShortcuts,
        availableInterval: getDiffBtwCurrDayToMinSocDate,
      }"
      keyToStoreDefaults="feed-graph-table-pref"
      datePickerFormat="yyyy-MM-dd"
      :datePickerDisabled="getSelectedPonds.length > 1"
      @date-query="handleDateQuery($event, 'FEED')"
    >
      <p slot="title">{{ $t("Comn_feed") }}</p>
      <el-row slot="legend" class="legend-container">
        <el-col class="legend" :span="14">
          <span
            v-for="item in getLegend"
            :key="item.color"
            class="legend__item"
          >
            <div
              class="color-box"
              :style="{ 'background-color': item.color }"
            />
            <p class="title">{{ item.name }}</p>
          </span>
        </el-col>
        <el-col :span="9" class="legend legend--right">
          <span class="legend__item legend__item--solid-line">
            <hr class="despFeed" />
            <p class="title">{{ $t("PM_dispensed_feed") }}</p>
          </span>
          <span class="legend__item legend__item--dash-line">
            <hr />
            <p class="title">{{ $t("PM_day_feed") }}</p>
          </span>
        </el-col>
      </el-row>
    </card-chart>

    <card-chart
      v-loading="loading.DO || getAllGraphLoading"
      element-loading-background="white"
      :columns="getDoColumns"
      :tableData="getDoTableData"
      :timeZoneString="getUserTimeZoneString"
      :chartConfig="getDOChartOptions"
      :xAxisTitle="$t('Comn_date_and_time')"
      :yAxisTitle="[$t('PM_dispensed_feed_kg'), $t('Comn_do_with_units')]"
      :initDateRangeArr="getDateRangeForDOGraph"
      :datePickerOptions="{
        disableDateMethod,
        arrShortcuts: getHourlyShortcuts,
        availableInterval: getDiffBtwCurrDayToMinSocDate,
      }"
      keyToStoreDefaults="dissolved-oxygen-graph-table-pref"
      datePickerFormat="yyyy-MM-dd"
      :datePickerDisabled="false"
      @date-query="handleDateQuery($event, 'DO')"
    >
      <p slot="title">{{ "DO" + " vs " + $t("Comn_hourly_feed") }}</p>
      <el-row slot="legend" class="legend-container">
        <el-col class="legend" :span="15">
          <span
            v-for="item in getLegend"
            :key="item.color"
            class="legend__item"
          >
            <div
              class="color-box"
              :style="{ 'background-color': item.color }"
            />
            <p class="title">{{ item.name }}</p>
          </span>
        </el-col>

        <el-col :span="9" class="legend legend--right">
          <span class="legend__item legend__item--dash-line">
            <hr />
            <p class="title">DO</p>
          </span>
        </el-col>
      </el-row>
    </card-chart>
    <card-chart
      v-loading="loading.ABW || getAllGraphLoading"
      element-loading-background="white"
      :columns="getAbwColumns"
      :tableData="getAbwTableData"
      :timeZoneString="getUserTimeZoneString"
      :chartConfig="getABWChartOptions"
      :xAxisTitle="$t('Comn_date')"
      :yAxisTitle="[$t('abw_gms'), `${$t('Comn_biomass')} (kgs)`]"
      :initDateRangeArr="getDateRangeForABWGraph"
      :datePickerOptions="{
        disableDateMethod,
        arrShortcuts: getDayWiseShortcuts,
        availableInterval: getDiffBtwCurrDayToMinSocDate,
      }"
      keyToStoreDefaults="abw-graph-table-pref"
      datePickerFormat="yyyy-MM-dd"
      :datePickerDisabled="false"
      @date-query="handleDateQuery($event, 'ABW')"
    >
      <p slot="title">{{ $t("ABW_abw") + " vs " + $t("Comn_biomass") }}</p>
      <el-row slot="legend" class="legend-container">
        <el-col class="legend" :span="15">
          <span
            v-for="item in getLegend"
            :key="item.color"
            class="legend__item"
          >
            <div
              class="color-box"
              :style="{ 'background-color': item.color }"
            />
            <p class="title">{{ item.name }}</p>
          </span>
        </el-col>
        <el-col :span="9" class="legend legend--right">
          <span class="legend__item legend__item--dash-line">
            <hr />
            <p class="title">{{ $t("Comn_biomass") }}</p>
          </span>
        </el-col>
      </el-row>
    </card-chart>
  </el-row>
</template>

<script>
import cardChart from "../../graphs/cardChart";
import { feedConfig, ABWConfig, doConfig } from "../utils/chartOptions";
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { getColorWithIndex } from "@/config/baseChartConfig";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  name: "InfoChart",
  mixins: [errorHandlerMixin, datesHandlerMixin, userPreferenceMixin],
  data: function () {
    return {
      feedChartOptions: feedConfig,
      abwChartOptions: ABWConfig,
      doChartOptions: doConfig,
      doQuery: {},
      feedXAxisType: "datetime",
      loading: {
        FEED: false,
        DO: false,
        ABW: false
      },
      colorToPondMap: {},
      stackOfcolorNotAllocated: [],
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      }
    };
  },
  components: {
    cardChart
  },
  computed: {
    ...mapGetters("pondInfo", {
      selectedPonds: "selectedPonds",
      doData: "doData",
      scheduleFeed: "scheduleFeed",
      abwData: "abwData",
      dateRangeForFeedGraph: "getDateRangeForFeedGraph",
      dateRangeForABWGraph: "getDateRangeForABWGraph",
      dateRangeForDOGraph: "getDateRangeForDOGraph",
      getAllGraphLoading: "getAllGraphLoading",
      getMinStartCultureDate: "getMinStartCultureDate",
      getPondColorIds: "getPondColorIds"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    getDiffBtwCurrDayToMinSocDate: function () {
      const actualMinCultureDate = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getMinStartCultureDate,
        this.getUserTimeZoneString
      );
      const currDayInGivenTZ = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
      return { start: actualMinCultureDate, end: currDayInGivenTZ };
    },
    getDayWiseShortcuts: function () {
      const diffBtnCurrToSocInDays = this.getDiffBtwCurrDayToMinSocDate;
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          diffBtnCurrToSocInDays,
          "days",
          `${this.$t("Comn_from")} ${this.$t("Comn_soc")}`
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          1,
          "days",
          this.$t("Comn_yesterday"),
          false
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "months"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(6, "months")
      ];
    },
    getHourlyShortcuts: function () {
      const diffBtnCurrToSocInDays = this.getDiffBtwCurrDayToMinSocDate;
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          diffBtnCurrToSocInDays,
          "days",
          `${this.$t("Comn_from")} ${this.$t("Comn_soc")}`
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          1,
          "days",
          this.$t("Comn_yesterday"),
          false
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(5, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(3, "months"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(6, "months")
      ];
    },
    getLegend() {
      const list = [];
      const series = this.getScheduleFeedChartOptions.series;
      for (var i = 0; i < series.length - 1; i++) {
        const { name, colorIndex } = series[i];
        if (i === 0 || !(i % 2)) {
          list.push({
            name,
            color: getColorWithIndex(colorIndex)
          });
        }
      }
      return list;
    },
    getSelectedPonds() {
      return this.selectedPonds || [];
    },
    getDOData() {
      return this.doData || [];
    },
    getScheduleFeedData() {
      return this.scheduleFeed || [];
    },
    getABWData() {
      return this.abwData || [];
    },
    getPondDarkColorId() {
      return (pondId) => {
        return 2 * this.getPondColorIds[pondId];
      };
    },
    getPondLightColorId() {
      return (pondId) => {
        return 2 * this.getPondColorIds[pondId] + 1;
      };
    },
    getFeedTableData() {
      const data = (this.getScheduleFeedData || []).map(
        ({ title, feedList, dispFeedList }) => {
          const arr3 = feedList.map((item, i) =>
            Object.assign({}, item, dispFeedList[i])
          );
          var result = {};
          for (var i = 0; i < arr3.length; i++) {
            const item = {
              dispensedFeed: arr3[i].dispensed_feed,
              feed: arr3[i].feed
            };
            result[arr3[i].newDate] = item;
          }
          result.title = title;
          return result;
        }
      );
      return data;
    },
    getAbwTableData() {
      const data = this.getABWData.map(({ title, abwData, biomassData }) => {
        const arr3 = abwData.map((item, i) =>
          Object.assign({}, item, biomassData[i])
        );
        var result = {};
        for (var i = 0; i < arr3.length; i++) {
          const item = {
            abwData: arr3[i].abw,
            biomassData: arr3[i].biomass
          };
          result[arr3[i].formattedDate] = item;
        }
        result.title = title;
        return result;
      });
      return data;
    },
    getDoTableData() {
      const data = this.getDOData.reduce(
        (tableDataArr, { title, doData, feedData }) => {
          const rowData = [
            { data: doData, key: "doData", value_key: "dissolved_oxygen" },
            { data: feedData, key: "feedData", value_key: "dispensed_feed" }
          ].reduce((rowDataObj, dataIterObj) => {
            if (dataIterObj.data.length > 0) {
              dataIterObj.data.forEach((dataItem) => {
                if (!rowDataObj[dataItem.newDate]) {
                  rowDataObj[dataItem.newDate] = {};
                }
                rowDataObj[dataItem.newDate][dataIterObj.key] =
                  dataItem[dataIterObj.value_key];
              });
            }
            return rowDataObj;
          }, {});
          if (Object.keys(rowData).length > 0) {
            rowData.title = title;
            tableDataArr.push(rowData);
          }
          return tableDataArr;
        },
        []
      );
      return data;
    },
    getScheduleFeedChartOptions() {
      const series = [];
      const ponds = this.getSelectedPonds.reduce((acc, pond) => {
        if (pond) {
          acc[pond._id] = pond;
        }
        return acc;
      }, {});
      const pondCount = this.getSelectedPonds.length;
      const xAxisGenerator =
        pondCount === 1
          ? (date) => date
          : (date, pond_id) => {
              const value = this.dhm__dateUtilsLib.differenceInDays(
                new Date(date),
                new Date(
                  (
                    ponds[pond_id] || { cultivation_date: new Date() }
                  ).cultivation_date
                )
              );
              return value + 1;
            };
      let xMin = Date.now();
      let xMax = 0;
      for (var i = 0; i < this.getScheduleFeedData.length; i++) {
        const { dispFeedList, feedList, title, pond_id } =
          this.getScheduleFeedData[i];

        const fFeed = feedList.reduce((acc, { newDate, feed }, index) => {
          const x = xAxisGenerator(newDate, pond_id);
          if (x > 0) {
            if (i === 0) {
              xMin = x;
              xMax = x;
            }
            xMax = Math.max(x, xMax);
            xMin = Math.min(x, xMin);
            acc.push({
              x: xAxisGenerator(newDate, pond_id),
              y: this.upm__convertUnits(
                feed || 0,
                this.upm__backendUnits.feed_units,
                this.upm__feedUnits
              )
            });
          }
          return acc;
        }, []);
        const fdFeed = dispFeedList.reduce(
          (acc, { newDate, dispensed_feed }, index) => {
            const x = xAxisGenerator(newDate, pond_id);
            if (x > 0) {
              xMax = Math.max(x, xMax);
              xMin = Math.min(x, xMin);
              acc.push({
                x: xAxisGenerator(newDate, pond_id),
                y: Number(
                  this.upm__convertUnits(
                    dispensed_feed,
                    this.upm__backendUnits.feed_units,
                    this.upm__feedUnits
                  )
                ),
                feed_value: (feedList[index] || { feed: 0 }).feed
              });
            }
            return acc;
          },
          []
        );
        series.push({
          id: `${title}_DF`,
          name: title,
          nameLg: `${this.$t("PM_dispensed_feed")} (${this.$tc(
            this.upm__feedUnits,
            0
          )})`,
          pondCount: pondCount,
          tableLgOrder: 1,
          visible: true,
          data: fdFeed,
          linkedTo: title,
          dashStyle: "Solid",
          colorIndex: this.getPondDarkColorId(pond_id),
          zIndex: 0,
          marker: {
            enabled: false
          }
        });

        series.push({
          id: `${title}_TF`,
          name: title,
          nameLg: `${this.$t("PM_day_feed")} (${this.$tc(
            this.upm__feedUnits,
            0
          )})`,
          pondCount: pondCount,
          tableLgOrder: 2,
          data: fFeed,
          colorIndex: this.getPondDarkColorId(pond_id),
          zIndex: 1,
          dashStyle: "dash",
          visible: true
        });
      }
      if (pondCount > 1) {
        feedConfig.chart_options.xAxis.type = "linear";
        feedConfig.chart_options.xAxis.allowDecimals = true;
        feedConfig.chart_options.xAxis.min = xMin;
        feedConfig.chart_options.xAxis.max = xMax;
        feedConfig.chart_options.xAxis.minRange = 10;
        feedConfig.chart_options.xAxis.title.text = this.$t("Comn_doc_days");
      } else {
        feedConfig.chart_options.xAxis.type = "datetime";
        feedConfig.chart_options.xAxis.allowDecimals = false;
        feedConfig.chart_options.xAxis.min = xMin;
        feedConfig.chart_options.xAxis.max = xMax;
        feedConfig.chart_options.xAxis.minRange =
          series.length > 0 ? 24 * 1000 * 60 * 60 * 2 : undefined;
        feedConfig.chart_options.xAxis.title.text = this.$t("Comn_date");
      }
      return {
        ...feedConfig.chart_options,
        series
      };
    },
    getABWChartOptions() {
      const series = [];
      let xMax = 0;
      let xMin = Date.now();
      for (var i = 0; i < this.getABWData.length; i++) {
        const { abwData, biomassData, title, pondId } = this.getABWData[i];
        const fAwb = abwData.map(({ formattedDate, abw }) => {
          xMax = Math.max(formattedDate, xMax);
          xMin = Math.min(formattedDate, xMin);
          return [formattedDate, +abw];
        });
        const fBio = biomassData.map(({ formattedDate, biomass }, index) => {
          xMax = Math.max(formattedDate, xMax);
          xMin = Math.min(formattedDate, xMin);
          return {
            x: formattedDate,
            y: this.upm__convertUnits(
              +biomass,
              this.upm__backendUnits.biomass_units,
              this.getPreferredUnits.biomass_units
            ),
            abw: this.upm__convertUnits(
              +(abwData[index] || { abw: 0 }).abw,
              this.upm__backendUnits.abw_and_growth_units,
              this.getPreferredUnits.abw_and_growth_units
            )
          };
        });
        series.push({
          name: title,
          nameLg: `${this.$t("PM_bio_mass")} (${this.$tc(
            this.getPreferredUnits.biomass_units,
            0
          )})`,
          tableLgOrder: 2,
          type: "column",
          data: fBio,
          maxPointWidth: 20,
          zIndex: 1,
          yAxis: 1,
          colorIndex: this.getPondDarkColorId(pondId),
          dashStyle: "dash",
          visible: true,
          tooltip: {
            // xDateFormat: '%Y-%m-%d',
            xDateFormat: this.dateFormatObject[this.upm__getFormatDateString],

            footerFormat: "</table>",
            headerFormat: "<small><strong>{point.key}</strong></small><table>",
            pointFormatter: function () {
              return (
                `<tr><td class="highcharts-tooltip__point__title" style="color: ${this.series.color}">${this.series.name}: </td>` +
                `<td style="text-align: right"><b>${(+this.abw.toFixed(
                  2
                )).toString()}</b>/<b>${(+this.y.toFixed(
                  2
                )).toString()}</b></td></tr>`
              );
            }
          },
          stack: 0
        });
        series.push({
          name: title,
          nameLg: this.$t("abw_gms"),
          tableLgOrder: 1,
          type: "spline",
          colorIndex: this.getPondDarkColorId(pondId),
          data: fAwb,
          dashStyle: "dash",
          zIndex: 2,
          yAxis: 0,
          tooltip: {
            pointFormat: ""
          },
          visible: true,
          stack: 0
        });
      }
      ABWConfig.chart_options.xAxis.min = xMin;
      ABWConfig.chart_options.xAxis.max = xMax;
      return {
        ...ABWConfig.chart_options,
        series
      };
    },
    getDOChartOptions() {
      const series = [];
      let xMin = Date.now();
      let xMax = 0;
      for (var i = 0; i < this.getDOData.length; i++) {
        const { doData, feedData, title, pondId } = this.getDOData[i];
        const dateToFeedMap = {};
        const fFeed = feedData.map(({ newDate, dispensed_feed }, index) => {
          xMax = Math.max(newDate, xMax);
          xMin = Math.min(newDate, xMin);
          dateToFeedMap[newDate] = +dispensed_feed;
          return {
            x: newDate,
            y: this.upm__convertUnits(
              +dispensed_feed,
              this.upm__backendUnits.feed_units,
              this.upm__feedUnits
            )
          };
        });
        const fDo = doData.map(({ newDate, dissolved_oxygen }) => {
          xMax = Math.max(newDate, xMax);
          xMin = Math.min(newDate, xMin);
          return {
            x: newDate,
            y: +dissolved_oxygen,
            feed: this.upm__convertUnits(
              +dateToFeedMap[newDate] || 0,
              this.upm__backendUnits.feed_units,
              this.upm__feedUnits
            )
          };
        });
        series.push({
          id: title + "_HF",
          name: title,
          nameLg: `${this.$t("Comn_hourly_feed")} (${this.$tc(
            this.upm__feedUnits,
            0
          )})`,
          tableLgOrder: 2,
          type: "column",
          data: fFeed,
          zIndex: 1,
          yAxis: 0,
          colorIndex: this.getPondDarkColorId(pondId),
          visible: true
        });
        series.push({
          id: title + "_DO",
          name: title,
          nameLg: this.$t("Comn_do_with_units"),
          tableLgOrder: 1,
          type: "spline",
          data: fDo,
          zIndex: 1,
          dashStyle: "dash",
          yAxis: 1,
          colorIndex: this.getPondDarkColorId(pondId),
          visible: true
        });
        doConfig.chart_options.xAxis.min = xMin;
        doConfig.chart_options.xAxis.max = xMax;
        doConfig.chart_options.tooltip.shared = false;
      }
      return {
        ...doConfig.chart_options,
        series
      };
    },
    getScheduleFeedColumns() {
      if (this.getFeedTableData.length > 0) {
        const columns = Object.keys(this.getFeedTableData[0]);
        columns.pop();
        columns.sort((a, b) => b - a);
        const newColumns = {
          title: {
            label: this.$t("Comn_pond_name"),
            width: "100",
            fixed: true
          }
        };
        for (var i = 0; i < columns.length; i++) {
          const date = new Date(parseInt(columns[i]));
          newColumns[columns[i]] = {
            label: this.dhm__formatByTZOnUTCWithLocale(
              date,
              this.getPreferredUnits.date_format
            )
          };
        }
        return newColumns;
      } else {
        return undefined;
      }
    },
    getAbwColumns() {
      if (this.getAbwTableData.length > 0) {
        const columns = Object.keys(this.getAbwTableData[0]);
        if (columns.length === 1) {
          return undefined;
        }
        columns.pop();
        columns.sort((a, b) => b - a);
        const newColumns = {
          title: {
            label: this.$t("Comn_pond_name"),
            width: "100",
            fixed: true
            // prop: "title"
          }
        };
        for (var i = 0; i < columns.length; i++) {
          const date = new Date(parseInt(columns[i]));
          newColumns[columns[i]] = {
            label: this.dhm__formatByTZOnUTCWithLocale(
              date,
              this.getPreferredUnits.date_format
            )
            // width: "60"
          };
        }
        return newColumns;
      } else {
        return undefined;
      }
    },
    getDoColumns() {
      if (this.getDoTableData.length > 0) {
        const columns = Object.keys(this.getDoTableData[0]);
        columns.pop();
        columns.sort((a, b) => b - a);
        const newColumns = {
          title: {
            label: this.$t("Comn_pond_name"),
            width: "100",
            fixed: true
          }
        };
        for (var i = 0; i < columns.length; i++) {
          const date = new Date(parseInt(columns[i]));
          newColumns[columns[i]] = {
            label: this.dhm__formatByTZOnUTCWithLocale(
              date,
              this.getPreferredUnits.date_format
            ),
            subLabel: this.dhm__formatByTZOnUTCWithLocale(
              date,
              this.getPreferredUnits.time_format
            )
            // width: "60"
          };
        }
        return newColumns;
      } else {
        return undefined;
      }
    },
    getDateRangeForFeedGraph() {
      return this.dateRangeForFeedGraph;
    },
    getDateRangeForABWGraph() {
      return this.dateRangeForABWGraph;
    },
    getDateRangeForDOGraph() {
      return this.dateRangeForDOGraph;
    }
  },
  methods: {
    ...mapActions("pondInfo", {
      changeDateRangeInGraph: "changeDateRangeInGraph",
      changeAllGraphLoadingStatus: "changeAllGraphLoadingStatus"
    }),
    disableDateMethod: function (time) {
      const soc = this.getMinStartCultureDate;
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__getTodayInUserTZ
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.parseISO(soc),
          this.getUserTimeZoneString
        )
      );
      const currDate = time;
      const paramObj = {
        dateRange: [startDate, endDate],
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    async handleDateQuery(dateKey, graphType) {
      this.loading[graphType] = true;
      try {
        const data = {
          graph_key: graphType,
          date: dateKey
        };
        await this.changeDateRangeInGraph(data);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading[graphType] = false;
        this.changeAllGraphLoadingStatus(false);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../../styles/mixins.scss";
.info-charts {
  .el-card {
    @include responsiveProperty(width, 435px, 543px, 704px);
    min-height: 250px;
  }
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  .legend-5-p {
    flex-direction: column !important;
    align-content: center;
    align-items: center;
  }
  .legend-container {
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
    padding-left: 1.5em;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
  }
  .table-row .legend-container {
    padding-top: 15px;
  }
  .legend {
    display: flex;
    justify-content: flex-start;
    &--right {
      justify-content: flex-end;
    }
    .legend__item {
      display: inline-flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: 10px;
      }
      .title {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        margin-left: 3px;
      }
      &--dash-line {
        hr {
          width: 1em;
          min-height: 0;
          border-style: unset;
          border-top: 2px dashed black;
        }
      }
      &--solid-line {
        hr {
          width: 1em;
          min-height: 0;
          background-color: black;
          border-top: 1px solid black;
        }
      }
    }
    .color-box {
      @include responsiveProperty(
        width,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      @include responsiveProperty(
        height,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      // width: 11px;
      // height: 11px;
      border-radius: 4px;
    }
  }
}
</style>
